import { Injectable } from '@angular/core';
import { HttpRequestService } from '@core/http/http-request.service';
import { APIEndpoint } from 'app/constants/api-endpoint';
import { Observable } from 'rxjs';
import { LoginCredential } from '../models/login-credential';
import { USER_KEY } from 'app/constants/constant';
import { MessageService } from '@shared/message/message.service';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _http: HttpRequestService, private messageService: MessageService) {}

  public login(loginPayload: LoginCredential): Observable<any> {
    return this._http.post(APIEndpoint.SIGNIN, loginPayload);
  }

  public agentLogin(payload: any){
    return this._http.post(APIEndpoint.GET_ONE_TIME_PASSWORD, payload)
  }

  public verifyOtp(payload: any){
    return this._http.post(APIEndpoint.VERIFY_ONE_TIME_PASSWORD, payload)
  }

  public sendOtp(payload: any){
    return this._http.post(APIEndpoint.SEND_FORGET_PASSWORD_OTP, payload)
  }

  public verifyOtpForgetPassword(payload: any){
    return this._http.post(APIEndpoint.VERIFY_OTP_FOR_FORGET_PASSWORD, payload)
  }

  public setNewPassword(payload: any){
    return this._http.post(APIEndpoint.SET_NEW_PASSWORD_AFTER_FORGET, payload)
  }

  public verifySignIn(payload: any){
    return this._http.post(APIEndpoint.VERIFY_SIGN_IN, payload)
  }

  public logOut() {
    return this._http.get(APIEndpoint.SIGNOUT);
  }

  public saveAuthUser(user: any) {
    const authUser = {
      token: user.token,
      refresh_token: user.refresh_token
    }
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(authUser));
  }

  getAuthUser(){
    try{
      return JSON.parse(window.sessionStorage.getItem(USER_KEY) as string);
    }
    catch(e){
      this.messageService.error("Please use valid user ID and password for login");
      return null;
    }
  }

  refreshToken(token: any) {
    const payload = {
      refresh_token: token
    }
    return this._http.post(APIEndpoint.REFRESH_TOKEN, payload);
  }

  get isLoggedIn(): boolean {
    const authUser = this.getAuthUser();
    if (authUser) {
        return this.isTokenPayloadValid(authUser.token) && !this.isTokenExpired(authUser.token);
    }
    return false;
  }

  getTokenExpirationDate(token: string): Date {
    let decoded: any
    try {
      decoded = jwt_decode(token);
    }
    catch (e) {
      this.messageService.error("Please use valid user ID and password for login");
    }

    if (decoded.exp === undefined) return null as any;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenPayloadValid(token: string): boolean {
    // let decoded!: any;
    // let tokenKeys!: any;
    // const compareKeys = ["login_id", "role_oid", "office_oid", "bin"];
    // try {
    //   decoded = jwt_decode(token);
    //   tokenKeys = Object.keys(decoded);
    //   console.log(tokenKeys)
    //   return tokenKeys.every((element: any) => compareKeys.includes(element));
    // }
    // catch (e) {
    //   this.messageService.error("Please use valid user ID and password for login");
    //   return false;
    // }
    return true;
  }

  isTokenExpired(token: string): boolean {
    if (!token) return true;

    const date: Date = this.getTokenExpirationDate(token);
    return !(date.valueOf() > new Date().valueOf());
  }

  clearStorage(): void {
    window.sessionStorage.clear();
  }

}
