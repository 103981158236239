import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/services/auth.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
     this.router.navigate(['/auth/login']);
    return false;
  }
}
