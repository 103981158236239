export class APIEndpoint {
  static SIGNIN = '/signin';
  static VERIFY_SIGN_IN = "/verify-signin";
  static SIGNOUT = '/signout';
  static GET_USER_INFORMATION = '/get-user-info';
  static REFRESH_TOKEN = '/token/refresh';
  static RESET_PASSWORD = '/password/reset';
  static HARD_RESET_PASSWORD = '/password/hard-reset';
  static CREATE_USER = '/user/create';
  static GET_USER_LIST = '/user/get-user-list';
  static UPDATE_USER_STATUS = '/user/update-user-status';
  static UPDATE_USER_INFO = '/user/update-user-info';
  static GET_USER_INFO_BY_OID = '/user/get-user-by-oid';
  static CHECK_USER_ID_AVAILABILITY = '/user/check-availability/userid';

  /*===================================================
------------------------Agent-------------------------
=====================================================*/
  static CNF_AGENT_SAVE = '/cnf-agent/save';
  static GET_INPROGRESS_CNF_AGENT_LIST = '/cnf-agent/get-inprogress-list';
  static GET_LICENCED_CNF_AGENT_LIST = '/cnf-agent/get-licenced-list';
  static FIND_AGENT_BY_AIN_NUMBER = '/cnf-agent/get-details-by-ain';
  static GET_LICENSED_AGENT_LIST = '/agent/get-licensed-agent-list';
  static UPLOAD_SUPPORTING_DOCS = '/common/add-document';
  static GET_SUPPORTING_DOCS_LIST = '/common/document-list';
  static GET_AGENT_DETAILS_BY_AIN_NUMBER = '/cnf-agent/get-by-ain';
  static AGENT_UPDATE = '/cnf-agent/update';
  static AGENT_LIFE_CYCLE = '/agent/life-cycle';
  static UPDATE_AGENT_STATUS = '/cnf-agent/update-status';

  /*===================================================
------------------------Ff-Agent-------------------------
=====================================================*/
  static FF_AGENT_CREATE = '/ff-agent/save';
  static FF_GET_INPROGRESS_AGENT_LIST = '/ff-agent/get-inprogress-list';
  static FF_GET_LICENCED_AGENT_LIST = '/ff-agent/get-licenced-list';
  static FF_FIND_AGENT_BY_AIN_NUMBER = '/ff-agent/get-details-by-ain';
  static FF_GET_LICENSED_FF_AGENT_LIST = '/ff-agent/get-licensed-agent-list';
  static FF_UPLOAD_SUPPORTING_DOCS = '/ff-common/add-document';
  static FF_GET_SUPPORTING_DOCS_LIST = '/ff-common/document-list';
  static FF_GET_AGENT_DETAILS_BY_AIN_NUMBER = '/ff-agent/get-by-ain';
  static FF_AGENT_UPDATE = '/ff-agent/update';
  static FF_AGENT_LIFE_CYCLE = '/ff-agent/life-cycle';
  static FF_UPDATE_AGENT_STATUS = '/ff-agent/update-status';
  static FF_UPDATE_DOCUMENT = '/ff-common/update-document';

  /*===================================================
  ------------------------Sp-Agent-------------------------
  =====================================================*/
  static SP_AGENT_SAVE = '/sp-agent/save';
  static SP_GET_INPROGRESS_AGENT_LIST = '/sp-agent/get-inprogress-list';
  static SP_GET_LICENCED_AGENT_LIST = '/sp-agent/get-licenced-list';
  static SP_FIND_AGENT_BY_AIN_NUMBER = '/sp-agent/get-details-by-ain';
  static GET_LICENCED_SP_AGENT_LIST = '/sp-agent/get-licenced-list';
  static SP_UPLOAD_SUPPORTING_DOCS = '/sp-common/add-document';
  static SP_GET_SUPPORTING_DOCS_LIST = '/sp-common/document-list';
  static SP_GET_AGENT_DETAILS_BY_AIN_NUMBER = '/sp-agent/get-by-ain';
  static SP_AGENT_UPDATE = '/sp-agent/update';
  static SP_AGENT_LIFE_CYCLE = '/sp-agent/life-cycle';
  static SP_UPDATE_AGENT_STATUS = '/sp-agent/update-status';
  static SP_UPDATE_DOCUMENT = '/sp-common/update-document';

  /*===================================================
-----------------------Owner-------------------------
=====================================================*/
  static CNF_OWNER_SAVE = '/cnf-owner/save';
  static CNF_GET_OWNER_LIST = '/cnf-owner/get-list';
  static FIND_OWNER_LIST_BY_AIN_NUMBER = '/cnf-owner/get-list-by-ain';
  static FIND_OWNER_BY_OID = '/cnf-owner/get-by-oid';
  static OWNER_UPDATE = '/cnf-owner/update';

  /*===================================================
-----------------------Ff-Owner-------------------------
=====================================================*/
  static FF_OWNER_CREATE = '/ff-owner/save';
  static FF_GET_OWNER_LIST = '/ff-owner/get-list';
  static FF_FIND_OWNER_LIST_BY_AIN_NUMBER = '/ff-owner/get-list-by-ain';
  static FF_FIND_OWNER_BY_OID = '/ff-owner/get-by-oid';
  static FF_OWNER_UPDATE = '/ff-owner/update';
  static FF_OWNER_UPDATE_STATUS = '/ff-common/update-status';

  /*===================================================
  -----------------------Sp-Owner-------------------------
  =====================================================*/
  static SP_OWNER_CREATE = '/sp-owner/save';
  static GET_SP_OWNER_LIST = '/sp-owner/get-list';
  static SP_FIND_OWNER_LIST_BY_AIN_NUMBER = '/sp-owner/get-list-by-ain';
  static SP_FIND_OWNER_BY_OID = '/sp-owner/get-by-oid';
  static SP_OWNER_UPDATE = '/sp-owner/update';
  static SP_OWNER_UPDATE_STATUS = '/sp-common/update-status';

  /*===================================================
-------------------Sarkar----------------------------
=====================================================*/
  static CNF_SARKAR_SAVE = '/cnf-sarkar/save';
  static CNF_GET_SARKAR_LIST = '/cnf-sarkar/get-list';
  static FIND_SARKAR_BY_AIN_NUMBER = '/cnf-sarkar/get-list-by-ain';
  static FIND_SARKAR_BY_OID = '/cnf-sarkar/get-by-oid';
  static SARKAR_UPDATE = '/cnf-sarkar/update';

  /*========================================================
--------------------------Documents------------------------
==========================================================*/
  static GET_SUPPORTING_DOCUMENT_LIST = '/get-supporting-document-list';
  static UPLOAD_SUPPORTING_DOCUMENT = '/upload-supporting-document';
  static UPDATE_SUPPORTING_DOCUMENT = '/update-supporting-document';
  static DELETE_SUPPORTING_DOCUMENT = '/delete-supporting-document';
  static UPLOAD_FILE = '/file/upload';
  static DOWNLOAD_FILE = '/file/download';
  static DELETE_FILE = '/file/delete';

  /*========================================================
--------------------------Reports----------------------------
==========================================================*/
  static DOWNLOAD_CNF_AGENT_INFO = '/cnf-agent/get-by-oid';
  static BULK_DOWNLOAD_CNF_AGENT_INFO = '/cnf-agent/get-by-oids';
  static DOWNLOAD_FF_AGENT_INFO = '/ff-agent/get-by-oid';
  static BULK_DOWNLOAD_FF_AGENT_INFO = '/ff-agent/get-by-oids';
  static DOWNLOAD_SHIPPING_AGENT_INFO = '/shipping-agent/get-by-oid';
  static BULK_DOWNLOAD_SHIPPING_AGENT_INFO = '/shipping-agent/get-by-oids';

  static UPDATE_STATUS = '/common/update-status';
  static UPDATE_LICENSED_AGENT_STATUS = '/cnf-agent/licensed/update-status';
  static UPDATE_LICENSED_FF_AGENT_STATUS = '/ff-agent/licensed/update-status';
  static UPDATE_LICENSED_SP_AGENT_STATUS = '/sp-agent/licensed/update-status';
  static CNF_UPDATE_OWNER_SARKAR_STATUS = '/cnf-common/update-status';

  static TOTAL_AGENT_SUMMARY = '/report/agent/summary/get-list';
  static TOTAL_AGENT_SUMMARY_REPORT_DOWNLOAD =
    '/report/agent/summary/download/xls';
  static TOTAL_OWNER_SUMMARY = '/report/owner/summary/get-list';
  static TOTAL_OWNER_SUMMARY_REPORT_DOWNLOAD =
    '/report/owner/summary/download/xls';
  static TOTAL_SARKAR_SUMMARY = '/report/sarkar/summary/get-list';
  static TOTAL_SARKAR_SUMMARY_REPORT_DOWNLOAD =
    '/report/sarkar/summary/download/xls';
  static TOTAL_BIN_VERIFIED_AGENT_SUMMARY =
    '/report/bin-verified/agent/summary/get-list';
  static TOTAL_BIN_VERIFIED_AGENT_SUMMARY_REPORT_DOWNLOAD =
    '/report/bin-verified/agent/summary/download/xls';
  static CUSTOMS_EMPLOYEE_CARD = '/customs/employee/card';
  static CNF_GET_SARKAR_CARD = '/cnf-sarkar/card';
  static GET_OWNER_CARD = '/owner/card';
  static AIN_BIN_TAGGING_REPORT_DOWNLOAD =
    '/report/agent-profile/ain-bin-tagging/download/xls';
  static TOTAL_AGENT_AIN_COUNT_PER_BIN_REPORT_DOWNLOAD =
    '/report/agent-profile/count-ain-per-bin/download/xls';
  static MY_AIN_BIN_TAGGED_REPORT_DOWNLOAD =
    '/report/agent-profile/my-ain-bin-tagged/download/xls';

  /*========================================================
--------------------------Others----------------------------
==========================================================*/
  static CHECK_AIN_AVAILABILITY = '/common/services/check-ain-availability';
  static CHECK_LICENSE_AVAILABILITY =
    '/common/services/check-license-availability';
  static FF_CHECK_AIN = '/ff-common/check-ain';
  static SP_CHECK_AIN = '/sp-common/check-ain';
  static CHECK_LICENCE = '/common/check-licnce';
  static FF_CHECK_LICENCE = '/ff-common/check-licnce';
  static SP_CHECK_LICENCE = '/sp-common/check-licnce';
  static VALIDATE_BIN = '/external/bin';
  static GET_OFFICE_LIST = '/common/services/get-office-list';
  static GET_REFERENCE_AIN_DETAILS = '/common/services/ain/reference/details';
  static COUNT_AGENT_AIN_BY_BIN = '/common/agents/count-ain-by-bin';
  static CHECK_BIN_VALIDITY = '/common/agents/bin/check-validity';

  /*========================================================
--------------------------Dashboard----------------------------
==========================================================*/
  static GET_DASHBOARD_DATA_LIST = '/dashboard/get-data-list';
  static GET_DASHBOARD_COUNT = '/dashboard/get-data-count';
  static GET_ALL_OFFICE_TOTAL_STATUS_COUNT =
    '/dashboard/all-office/get-total-status-count';
  static GET_AIN_BIN_TAGGING_TOTAL_STATUS_COUNT =
    '/dashboard/ain-bin-tagging/get-total-status-count';
  static GET_ALL_OFFICE_TOTAL_STATUS_COUNT_REPORT_DOWNLOAD =
    '/dashboard/all-office/get-total-status-count/download/xls';
  static GET_AIN_BIN_TAGGING_TOTAL_STATUS_COUNT_REPORT_DOWNLOAD =
    '/dashboard/ain-bin-tagging/get-total-status-count/download/xls';

  /*========================================================
--------------------------Case Management----------------------------
==========================================================*/

  static CASE_INFO_SAVE = '/case-info/save';
  static CASE_INFO_UPDATE = '/case-info/update';
  static CASE_INFO_GET_LIST = '/case-info/get-list';
  static CASE_INFO_GET_BY_OID = '/case-info/get-by-oid';
  static CASE_UPDATE_STATUS = '/case-info/update-case-status';
  static CHECK_CASE_AIN_VALIDITY = '/case/case-info/ain-validity';

  /*======================================================
  --------------Update Licenced Agent---------------------
  =======================================================*/

  static LICENCED_AGENT_INFO_SAVE = '/licenced-agent-info/save';
  static LICENCED_AGENT_GET_LIST = '/licenced-agent/get-list';
  static LICENCED_AGENT_UPDATE_STATUS = '/licenced-agent/update-status';
  static LICENCED_AGENT_INFO_GET_BY_OID = '/licenced-agent/get-by-oid';
  static LICENCED_AGENT_GET_CHANGED_INFO =
    '/licenced-agent/get-changed-info-new';

  /*==========================================================
  --------------------Agent Portal----------------------------
  ============================================================*/
  static GET_ONE_TIME_PASSWORD = '/public/sms/one-time-password';
  static VERIFY_ONE_TIME_PASSWORD = '/public/sms/one-time-password/verify';
  static AIN_BIN_TAGGING_REQUEST = '/agent/ain-bin/tagging/request';
  static GET_AGENT_PROFILE_LIFE_CYCLE = '/agent/profile/life-cycle';
  static GET_AGENT_PROFILE_INFORMATION_BY_BIN =
    '/agent/profile/information/get-by-bin';
  static GET_OFFICE_CODE_LIST = '/common/services/get-office-code-list';
  static GET_COMMISSIONER_OFFICE_CODE_LIST =
    '/common/services/get-commissioner-office-code-list';
  static GET_AIN_LIST_BY_OFFICE_OID = '/agent/ain/get-ain-list-by-office-oid';
  static GET_AGENT_PROFILE_LIST = '/agent/profile/get-list';
  static UPDATE_AGENT_PROFILE_STATUS = '/agent/profile/update-status';
  static GET_BIN_HISTORY = '/agent/profile/get-bin-history-list';
  static UPDATE_BIN_INFO = '/agent/profile/update-bin-info';
  static GET_BIN_DETAILS_BY_OID = '/agent/bin/get-bin-details-by-oid';
  static AGENT_PROFILE_LOCK = '/agent/profile/lock';
  static AGENT_PROFILE_UNLOCK = '/agent/profile/unlock';
  static GET_BIN_LIST = '/agent/profile/bin/get-bin-list';
  static BIN_INFO_SAVE = '/agent/profile/save-bin-info';
  static GET_AIN_LIST = '/agent/profile/ain/get-ain-list';
  static AIN_INFO_SAVE = '/agent/profile/ain/save-ain-info';
  static UPDATE_AIN_INFO = '/agent/profile/ain/update-ain-info';
  static GET_AIN_DETAILS_BY_OID = '/agent/ain/get-ain-details-by-oid';
  static CHECK_AIN_VALIDITY = '/agent/profile/ain/check-validity';
  static GET_REMOVE_AGENT_PROFILE_LIST = '/agent/profile/get-remove-list';

  /*==========================================================
--------------------Customs Employee----------------------------
============================================================*/

  static CUSTOMS_EMPLOYEE_INFO_GET_LIST = '/customs/employee/get-list';
  static CUSTOMS_EMPLOYEE_UPDATE_STATUS = '/customs/employee/update-status';
  static CUSTOMS_EMPLOYEE_INFO_SAVE = '/customs/employee/save-info';
  static CUSTOMS_EMPLOYEE_INFO_GET_BY_OID = '/customs/employee/get-by-oid';
  static CUSTOMS_EMPLOYEE_INFO_UPDATE = '/customs/employee/update-info';

  /*==========================================================
--------------------Bank----------------------------
============================================================*/

  static BANK_LIST = '/bank/list';
  static BRANCH_LIST = '/bank/branch/list';

    /*==========================================================
--------------------Designation Configuration----------------------------
============================================================*/
  static ADD_DESIGNATION = '/common/designation/add-designation';
  static GET_DESIGNATION_LIST = '/common/designation/get-list';
  static GET_DESIGNATION_NAME_LIST = '/common/designation/get-name-list';
  static UPDATE_DESIGNATION = '/common/designation/update-designation';

    /*==========================================================
--------------------Forget Password----------------------------
============================================================*/
  static SEND_FORGET_PASSWORD_OTP = "/send-otp";
  static VERIFY_OTP_FOR_FORGET_PASSWORD = "/verify-otp";
  static SET_NEW_PASSWORD_AFTER_FORGET = "/set-new-password";
}
